/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

/*Generales*/

@font-face {
  font-family: 'Basic font';
  src: url('assets/fonts/Montserrat-VariableFont_wght.ttf');
}

@import '@angular/material/theming';
$custom-typography: mat.define-typography-config(
  $font-family: 'Basic font'
);

@include mat.all-component-typographies($custom-typography);

:root {
  --primary-color: #FFFFFF;
  --second-color: #000000;
  --third-color: #673AB7;
  --complementary-color: #F5F5F5;
}

* {
  margin: 0px;
  padding: 0px;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  height: 100%;
  font-family: 'Basic font';
  font-size: 1.5rem;
  overflow: hidden;
  background: url('assets/img/fondo1.png');
  overflow-y: auto;
}

.mat-mdc-snack-bar-container.alert-success .mdc-snackbar__surface { 
  background-color: var(--primary-color);
  box-shadow: 11px 0px 0px -5px green inset, 0px 3px 5px -1px rgb(0 0 0 / 60%), 0px 6px 10px 0px rgb(0 0 0 / 20%), 0px 1px 18px 0px rgb(0 0 0 / 20%)
}

.mat-mdc-snack-bar-container.alert-warning .mdc-snackbar__surface { 
  background-color: var(--primary-color);
  box-shadow: 11px 0px 0px -5px orange inset, 0px 3px 5px -1px rgb(0 0 0 / 60%), 0px 6px 10px 0px rgb(0 0 0 / 20%), 0px 1px 18px 0px rgb(0 0 0 / 20%)
}

.mat-mdc-snack-bar-container.alert-danger .mdc-snackbar__surface { 
  background-color: var(--primary-color);
  box-shadow: 11px 0px 0px -5px red inset, 0px 3px 5px -1px rgb(0 0 0 / 60%), 0px 6px 10px 0px rgb(0 0 0 / 20%), 0px 1px 18px 0px rgb(0 0 0 / 20%)
}

.mat-mdc-snack-bar-container.alert-info .mdc-snackbar__surface { 
  background-color: var(--primary-color);
  box-shadow: 11px 0px 0px -5px blue inset, 0px 3px 5px -1px rgb(0 0 0 / 60%), 0px 6px 10px 0px rgb(0 0 0 / 20%), 0px 1px 18px 0px rgb(0 0 0 / 20%)
}